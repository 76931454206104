import { useHistory } from "react-router-dom";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/NavigationClient";
import SignInSignOutButton from "./components/login/containers/signInSignOutButton";

type AppProps = {
    pca: IPublicClientApplication
};

function App({ pca }: AppProps) {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const history = useHistory();
    const navigationClient = new CustomNavigationClient(history);
    pca.setNavigationClient(navigationClient);
  
    return (
      <MsalProvider instance={pca}>
        <SignInSignOutButton/>
      </MsalProvider>
    );
}
  

export default App;

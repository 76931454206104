import {
  SET_LIST_STATE,
  CLEAR_LIST_STATE,
  ListActionTypes,
} from "../types/listTypes";
import { Dispatch } from "redux";

export const ListAction = (
  type: any,
  listName = "",
  data = []
): ListActionTypes => {
  return {
    type: type,
    payload: data,
    listName: listName,
  };
};

export const createListState = (listName = "", data: []) => {
  return (dispatch: Dispatch<ListActionTypes>) => {
    dispatch(ListAction(SET_LIST_STATE, listName, data));
  };
};

export const clearListState = (listName = "") => {
  return (dispatch: Dispatch<ListActionTypes>) => {
    dispatch(ListAction(CLEAR_LIST_STATE, listName));
  };
};

import React from "react";

type initialState = {
  isError?: boolean;
  error?: string;
};

type initialProps = {
  msg?: string;
};

class ErrorBoundary extends React.Component {
  state: initialState = {
    isError: false,
    error: "",
  };

  componentDidCatch() {
    //@ts-ignore
    this.setState({ isError: true });
  }

  render() {
    //@ts-ignore
    const { children } = this.props;
    const { isError } = this.state;
    if (isError) {
      return (
        //@ts-ignore
        <div className="h-100 w-100 d-flex justify-content-center align-items-center flex-column">
          somthing went wrong
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;

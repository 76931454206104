import React from "react";
import { Spinner } from "reactstrap";

interface Props {
  id: string;
  type?: "grow" | "border";
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
  size?: "sm" | "lg";
  otherProps?: {};
}

export const CustomSpinner: React.FC<Props> = React.memo(
  ({
    id,
    size = "sm",
    type = "border",
    color = "primary",
    otherProps = {},
  }: Props) => {
    return (
      <Spinner id={id} size={size} color={color} type={type} {...otherProps} />
    );
  }
);

import { SHOW_ALERT, AlertActionTypes } from "../types/alertTypes";

export const alertState = (
  state = { message: "", type: "success", show: false, position: "top-right" },
  action: AlertActionTypes
) => {
  switch (action.type) {
    case SHOW_ALERT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

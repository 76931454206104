import React from 'react';
import { Route, BrowserRouter, Redirect } from 'react-router-dom';
import { HomePage } from './components/homePage/containers';
import { Login } from './components/login/containers';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Redirect exact={true} from="/" to="/" />
        <Route component={Login}/>
      </BrowserRouter>
    </div>
  );
}

export default App;

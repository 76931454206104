import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames/bind";
import { Button, Col, Container, Row } from "reactstrap";
import { ContactUsForm } from "./contactUsForm";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import styles from "./styles.module.scss";
import axios from "axios";
import parse from 'react-html-parser';


const cx = classnames.bind(styles);

interface Props {
    onCloseFAQ: () => void;
}
type faqdetail = {
    title: string;
    text: string;
  };
  
export const FAQComponent: FC<Props> = ({ onCloseFAQ }) => {
    const { t } = useTranslation();
    const [arrowDown, setArrowDown] = useState<boolean>(true);
    const [showFirstDiv, setShowFirstDiv] = useState("");
    const [contactUsForm, setContactUsForm] = useState<boolean>(false);
    const [faqData,setFaqData] = useState<faqdetail[]>([]);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        getFaqData()
    }, [])

    const getFaqData = async () => {
        try {
            const response: any = await axios.get(`${process.env.REACT_APP_API_URL_ENDPOINT}/Api/Login/LoginFaq`)
            setFaqData(response.data);
        }
        catch {

        }
    }
    return <div className={`${cx("loginFAQuestion")}`}>
        {
            contactUsForm ? 
                <ContactUsForm onCloseContactusForm={() => {
                    setContactUsForm(!contactUsForm)
                }}/>
                :
        
        <Container>
            <Row className="mt-4">
                        <Col md="12" className="p-0">
                            <ArrowBackIcon onClick={() => {
                                onCloseFAQ();
                            }
                            }/>
                         
                            <span className={`ms-2 ${cx("loginFAQuestionBackIcon")}`}
                            >{t("faq_questions")}</span>
                    </Col>
            </Row>
            {faqData.map((data: any, key: any) => {
                const html = data.text;
                return(
            <div key={key}><Row>
                        <Col md="12" className="mt-3 p-0">
                            <div key={key}>
                                <span style={{"cursor":"pointer"}} onClick={() => {
                                    setShowFirstDiv(key);
                                    setIsActive(!isActive);
                                    } }>
                                {isActive && showFirstDiv == key? 
                                    <ArrowDropDownIcon fontSize="medium"/>:
                                    <ArrowRightIcon fontSize="small" />
                                }
                                </span>
                                <span className={`ms-2 ${cx("loginFAQuestionDownIcon")}`}
                                onClick={() => {
                                    setShowFirstDiv(key); setIsActive(!isActive);}}>{data.title}</span>
                            </div>

                            {showFirstDiv == key && isActive ? <div className="mt-1 text-secondary" style={{ marginLeft: "2rem" }}>
                                <span className={`${cx("loginFAQuestionPassword")}`}
                                > {parse(html)}</span>
                            </div>:""}
                        </Col>
                    </Row></div>)})}

            <Row className="d-flex flex-column justify-content-center align-items-start mt-5">
                <Col md="12" style={{ textAlign: "center" }}>
                  
                            <span className={`${cx("loginNeedHelp")}`}>
                        <span>{t("need_help")}</span>
                        <Button className="d-inline-block ms-3" outline color="primary"
                            onClick={() => {
                                setContactUsForm(!contactUsForm)
                            }}
                        > {t("contact_us")}
                            </Button>
                    </span>
                </Col>
            </Row>
            </Container>
                }
    </div>;
};
export const SET_FORMS_STATE = "SET_FORMS_STATE";
export const CLEAR_FORMS_STATE = "CLEAR_FORMS_STATE";
export const UPDATE_FORM_STATE = "UPDATE_FORM_STATE";

interface SetFormsState {
  type:
    | typeof SET_FORMS_STATE
    | typeof CLEAR_FORMS_STATE
    | typeof UPDATE_FORM_STATE;
  payload: any;
  formName: string;
}

export type FormsActionTypes = SetFormsState;

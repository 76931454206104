import {
  SET_RAW_STATE,
  CLEAR_RAW_STATE,
  RawActionTypes,
} from "../types/rawTypes";
import { Dispatch } from "redux";

export const rawStateAction = (type: any, data = {}): RawActionTypes => {
  return {
    type: type,
    payload: data,
  };
};

export const setRawState = (data: {}) => {
  return (dispatch: Dispatch<RawActionTypes>) => {
    dispatch(rawStateAction(SET_RAW_STATE, data));
  };
};

export const clearRawState = () => {
  return (dispatch: Dispatch<RawActionTypes>) => {
    dispatch(rawStateAction(CLEAR_RAW_STATE));
  };
};

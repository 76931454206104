import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import {Button} from 'reactstrap';

export const SignOutButton = () => {
    const { instance } = useMsal();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleLogout = (logoutType: string) => {
        setAnchorEl(null);

        if (logoutType === "popup") {
            instance.logoutPopup({
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect();
        }
    }

    return (
        <div>
            <Button
                onClick={() => handleLogout("popup")} key="logoutPopup"
                color="inherit"></Button>
        </div>
    )
};
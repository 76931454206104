import React, { FC, useEffect, Dispatch, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Row, Col } from "reactstrap";
import { switchLanguage } from "../../../localization/switchLanguage";
import { showAlert } from "../../../redux/actions/alertActions";
import {
  createFormState,
  updateFormsState,
} from "../../../redux/actions/formsActions";
import {
  clearListState,
  createListState,
} from "../../../redux/actions/listActions";
import { clearRawState, setRawState } from "../../../redux/actions/rawActions";
import { AppState } from "../../../redux/store";
import { Loading } from "../../reusable/loading";
import loginTranslationObj from "../utils/helpers";
import { LoginLHS } from "../../login/containers/loginLHS";
import { LoginRHS } from "../../login/containers/loginRHS";
import { AzureLoginModal } from "../containers/azureLoginModal";


export const Login: FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const { t } = useTranslation();
    const { label } = loginTranslationObj;
    const rawState: any = useSelector((state: AppState) => state.rawState);
    const { name = "" } = rawState;
    const [lang, toggleLang] = useState<boolean>(true);
    const [azureModal, azuresetModal] = useState<boolean>(true);

  
  useEffect(() => {
    let alertObj: any = {
      message: "This is my alert",
      type: "danger",
      show: true,
    };
    // alter messge method
  //  dispatch(showAlert(alertObj));

    // clear row state method
    dispatch(clearRawState());

    // raw state set method
    dispatch(
      setRawState({
        name: "create form",
      })
    );

    // create form state for registration with empty object
    dispatch(createFormState("registration", {}));
    dispatch(createFormState("login", {}));
    // update form state with name
    dispatch(updateFormsState("registration", { name: "update key" }));
    // create list state
    let res: any = [
      {
        name: "a",
      },
      {
        b: "b",
      },
    ];
    let newRes: any = [
      {
        name: "asa",
      },
    ];
    dispatch(createListState("myArr", res));
    dispatch(createListState("myArr1", newRes));
    // clare list state
    dispatch(clearListState("myArr1"));
  }, []);

  return (
      <div className="container-fluid">
          {/* {t("mobile")} {name} {label}*/}
      {/* <Loading /> */}
          {/* <Button
        onClick={() => {
        //  debugger; 
          toggleLang(!lang);
          if (lang) {
            switchLanguage("en-US");
          } else {
            switchLanguage("en");
          }
        }}
      >
        Toggle lang
      </Button>*/}
          { /*  {azureModal && <AzureLoginModal onClose={() => {
              azuresetModal(!azureModal)
          }}
              isOpen={azureModal}
          />
          }*/}
          {/* <AzureLoginModal onClose={() => {
              azuresetModal(!azureModal)
          }}
              isOpen={azureModal}
          /> */}

              <Row>
                  <Col md="7" className="p-0">
                      <LoginLHS/>
                  </Col>
              <Col md="5" className="p-0">
                      <LoginRHS />
                  </Col>
                  </Row>
    </div>
  );
};

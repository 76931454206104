/// <reference path='../../../../dyo.d.ts' />
import React, { FC } from "react";
import classnames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { Popover, PopoverHeader, PopoverBody, Container, Row, Col } from 'reactstrap';
import styles from './styles.module.scss';
import parse from 'react-html-parser';

const cx = classnames.bind(styles);

interface Props {
    isOpen?: boolean;
    toggle: () => void;
    idNotification?: any;
    notificationData?: any;
}

export const NotificationModal: FC<Props> = ({ isOpen, toggle, idNotification, notificationData }) => {
    const { t } = useTranslation();
    const options = {
        replace: (domNode: { attribs: { class: string; }; }) => {
          if (domNode.attribs && domNode.attribs.class === 'remove') {
            return <></>;
          }
        },
      };
    return <div>
        <Popover placement="bottom" isOpen={isOpen} target={idNotification}
            toggle={() => {
            toggle();
        }}>
            <PopoverHeader className={`${cx("notificationModalHeader")}`}>
                {t("notifications")}</PopoverHeader>
        
            <PopoverBody className={`p-0 ${cx("notificationModalBody")}`}>{
                notificationData.map((data: any, key: any) => {
                    return (
                        < div id={key}  >
                            <Container>
                                <Row className="mt-1 fs-6 fw-bolder p-1 pb-0">
                                    <Col md="12"><span className={`${cx("notificationModalContainerTitle")}`}>{data.title}</span></Col>
                                </Row>
                                <Row className="p-1 mt-n1 pt-0">
                                    <Col md="12"><span className={`${cx("notificationModalContainerLabel")}`}>{data.publishDateText}</span></Col>
                                </Row>
                                <Row className="text-secondary p-1 pt-0">
                                    <Col md="12"><span className={`${cx("notificationModalContainerBody")}`}>{parse(data.text)}</span></Col>
                                </Row>
                                </Container>
                            <hr className={`${cx("marginBottom")}`} />
                        </div>
                     )
                })
                }</PopoverBody>
             
        </Popover>
    </div>;
};
import {
  SET_LIST_STATE,
  CLEAR_LIST_STATE,
  ListActionTypes,
} from "../types/listTypes";

export const listState = (state = {}, action: ListActionTypes) => {
  switch (action.type) {
    case SET_LIST_STATE:
      return { ...state, [action.listName]: [...action.payload] };
    case CLEAR_LIST_STATE:
      return { ...state, [action.listName]: [] };
    default:
      return state;
  }
};

import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";

const isProduction = process.env?.REACT_APP_BUILD_ENV === "production";
const middlewares = [thunkMiddleware];

const store = createStore(
  rootReducer,
  isProduction
    ? compose(applyMiddleware(...middlewares))
    : composeWithDevTools(applyMiddleware(...middlewares))
);

export type AppState = ReturnType<typeof rootReducer>;
export default store;

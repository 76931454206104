import React, { FC, useState, useEffect, useLayoutEffect } from "react";
import classnames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Row, Form, FormGroup, Label, Input } from "reactstrap";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from "./styles.module.scss";
import axios from "axios";
import ClientCaptcha from "react-client-captcha";
import captchaLoader from '../images/Vector.png';
import { validEmail } from "./emailvalidation";
import fileUploadIcon from '../images/file_upload_icon.png';
import attachFile from '../images/attach_file.png';
import clearIcon from '../images/clear.png';
import dropDwonIcon from '../images/dropdown_icon.png';
import { v4 as uuid } from 'uuid';
import { Link, useHistory } from "react-router-dom";
import emailIcon from "../images/email_icon.png";
const cx = classnames.bind(styles);

interface Props {
    onCloseContactusForm: () => void;
}

export const ContactUsForm: FC<Props> = ({ onCloseContactusForm }) => {
    const { t } = useTranslation();
    const [details, setDetails] = useState<string>("");
    const [categoryField, setCategoryField] = useState({});
    const [email, setEmail] = useState<string>("");
    const [verficationCode, setVerificationCode] = useState<string>("");
    const [isSubmit, setSubmit] = useState<boolean>(false);
    const [supportDropdown, setSupportDropdown] = useState([]);
    const [emailError, setEmailError] = useState(false);
    const [captchaError, setCaptchaError] = useState(false);
    const [captchaCode, setCaptchaCode] = useState<string>("");
    const [categoryId, setCategoryId] = useState<number>(0);
    const [fileName, setFileName] = useState("");
    const [selectedFile, setSelectedFile] = useState<any>([]);
    const [successPage, setSuccessPage] = useState<boolean>(false);
    const [hideFileUploadBtn, setHideFileUploadBtn] = useState<boolean>(false);
    const [errorSupportRequest, setErrorSupportRequest] = useState<boolean>(false);
	const hiddenFileInput = React.useRef<any>();
    
    useLayoutEffect(() => {
        if (details.length != 0 && email.length != 0 && !emailError && !captchaError && verficationCode.length != 0 && selectedFile.length != 0) {
            setSubmit(true)
        }
        else {
            setSubmit(false)
        }
        if(selectedFile.length > 0){
            setHideFileUploadBtn(true);
        } else{
            setHideFileUploadBtn(false);
        }
    }, [details,email,verficationCode,emailError,captchaError,selectedFile])
    
   const handleClick = (e:any) => {
        hiddenFileInput.current.click();
    };

    const postFileUpload = async (name:string, file:any) =>{
        const formData = new FormData();
        formData.append("selectedFile", file);
       try {
            const response = await axios({
              method: "post",
                url: `${process.env.REACT_APP_API_URL_ENDPOINT}/Api/Login/LoginUploadFile`,
              params:name,
              data: formData,
              headers: { "Content-Type": "multipart/form-data" },
            });
          } catch(error) {
            console.log(error)
          }
       }
    const emailValidation = (e: React.ChangeEvent<HTMLInputElement>) =>{
        var email = e.target.value
        if (validEmail.test(email)) {
            setEmailError(false);
        } else {
            setEmailError(true);
        }
    };

    const handleCaptcha1 = (e:any) => {
        let inputCaptcha = e.target.value;
        let generatedCaptcha = captchaCode;
        if (inputCaptcha.trim() === generatedCaptcha.trim()) {
            setCaptchaError(false);
        } else {
           setCaptchaError(true);
        }
      };

    useEffect(() => {
        getDropdownData();
        
    }, [])

    const getDropdownData = async () => {
        try {
            const response: any = await axios.get(`${process.env.REACT_APP_API_URL_ENDPOINT}/Api/LoginSupportCategory`);
            setSupportDropdown(response.data);
        }
        catch(error) {
            console.log(error)
        }
    }
    const supportCateory = (e:any) =>{
        let obj = JSON.parse(e.target.value);
        setCategoryId(obj.i); 
        setCategoryField(supportDropdown[categoryId]);
    }
    const formatBytes = (bytes:number, decimals = 2) => {
        if (bytes === 0) {
          return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Kb', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      }

    const handlePostContactSubmit = async (e:any) =>{
        setSubmit(false);
        e.preventDefault();
        const unique_id = uuid();
        console.log("selectedfile",selectedFile);
        const contactdetails = {"Category": categoryField,
            "Description": details,
            "Username": email,
            "Response": verficationCode,
            "Attachments": [{"id":unique_id,
                "name":selectedFile[0].name,
                "formatSize":formatBytes(selectedFile[0].size),
                "size": selectedFile[0].size,
                "loaded": 0,
                "status": 1,
                "percent": 100
                }]
        }
        axios.post(`${process.env.REACT_APP_API_URL_ENDPOINT}/Api/Login/Support`, contactdetails,
        { headers: {
                'Content-Type': "application/json",
                'Accept': 'application/json'
        }},)
        .then(res=>{
              if(res.headers.statuscode == 201 || res.headers.statuscode == 200 || res.data === "{}"){
                    console.log("submit",res.data);
                    setSuccessPage(true);
                    setErrorSupportRequest(false);
                } else {
                    console.log("submitError",res.data);
                    setErrorSupportRequest(true);
                    setSuccessPage(false);
                }
            }).catch(function (error) {
                console.log("error",error);
                setSuccessPage(false);
                setErrorSupportRequest(true);
            })
  }
    
    return (<div className={`${cx("loginContactUsForm")}`}>
       {successPage?
       <div style={{"marginLeft":"10%"}}>
       <Row className="d-flex flex-column justify-content-center align-items-start mt-4">
       <Col className="d-flex flex-column justify-content-start align-items-start">
           <img style={{"marginLeft":"25%"}} src={emailIcon}/>
           <div className={`${cx("azureLoginModal")}`}>{t("request_received_text")}</div>
           <div className={`${cx("request_received_description")}`}>{t("request_received_description")}</div>
           <div className={`${cx("bactToLoginMargin")}`}><button className={`${cx("back_to_login_btn")}`} onClick={() => {
                                window.location.reload();}}>{t("back_to_login_btn")}</button></div>
       </Col>
       </Row></div>: 
       <Container>
            <Row>
                <Col md="12">
                    <ArrowBackIcon className="mb-1" onClick={() => {
                        onCloseContactusForm();
                    }} />
                    <span className={`ms-2 ${cx("loginContactUsFormArrowIcon")}`}
                    >{t("getting_support")}</span>
                </Col>
            </Row>
            <Row className="d-flex flex-column justify-content-center align-items-center mt-4">
                <Col md="8" className="d-flex flex-column justify-content-end align-items-end">
                    <Form onSubmit={handlePostContactSubmit}>
                        <FormGroup>
                            <Label for="exampleModule" className={`${cx("loginContactUsFormWithLabel")}`}
                            >{t("trouble_with")}</Label>
                            <span className="text-danger ms-1">*</span>
                            <select className={`${cx("dropDownOption")}`} name="select" id="exampleModule"
                                onChange={(e) => {
                                    supportCateory(e)}} defaultValue={'DEFAULT'}>
                                <option value="DEFAULT" disabled>Please select your issue category</option>        
                                {supportDropdown.map((data: any, i: any) => {  
                                    return (<><option key={i} value={JSON.stringify({ i, value: data.name })}>{data.name}</option>
                                    </>)})}
                                </select>
                        </FormGroup>
                        <FormGroup className="mt-2">
                            <Label for="detialsText" className={`${cx("loginContactUsFormWithLabel")}`}
                            >{t("detials")}</Label>
                            <span className="text-danger ms-1">*</span>
                            <Input className="mt-1" type="textarea" name="text" id="detialsText" value={details}
                                onChange={(e) => {
                                    setDetails(e.target.value)
                                }}                            />
                            <span  className={`text-secondary ${cx("loginContactUsFormErrormsg")}`}
                            >{t("problem_error_msg")}</span>
                        </FormGroup>
                      
                        <FormGroup className="mt-2">
                            <Label for="exampleFile" className={`${cx("loginContactUsFormWithLabel")}`}>{t("attachment")}</Label>
                            <span className="text-danger ms-1">*</span>
                            <div className={`${cx("upload-file-support")}`} >
                                <div className="row">
                                    <div className="form-group">
                                        {hideFileUploadBtn?
                                        <div className="upload-button" style={{"marginBottom": "40px"}}>
                                            <div className={`${cx("fileUploadBtn")}`}
                                                style={{"color":"#DDDDDD", "cursor":"default"}} >
                                                <img src={fileUploadIcon} style={{"marginRight":"20px","display":"none"}}/>Upload file</div>
                                        </div>:
                                        <div className="upload-button" style={{"marginBottom": "40px"}}>
                                            <div className={`${cx("fileUploadBtn")}`} onClick={handleClick}>
                                                    <img src={fileUploadIcon} style={{"marginRight":"20px"}}/>Upload file</div>
                                                    <input type="file"  accept='.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.png,.jpeg,.txt'
                                                    ref={hiddenFileInput}
                                                    onChange={(e:any)=>{
                                                        const arr:any[] = [];
                                                        for(let size=0; size < e.target.files.length; size++){
                                                            let file = e.target.files[size];
                                                            setFileName(file.name);
                                                            postFileUpload(fileName, file);
                                                            arr.push(file);
                                                            }
                                                            setSelectedFile(arr);
                                                            console.log("sf",arr)
                                                        }}
                                                    style={{display:'none'}}/>
                                            </div>}
                                        </div> 
                                        
                                        {selectedFile.map((data:any,item:number)=>{
                                            return(<div key={item} style={{"marginTop":"10px"}}>
                                                <img src={attachFile} style={{"float":"left","padding":"5px"}}/>
                                                    <span style={{"float":"left"}}>{data.name}</span>
                                                <img src={clearIcon} onClick={(e)=> {
                                                    setSelectedFile([]);
                                                    }} style={{"float":"right","padding":"5px"}}/>
                                            </div>)})}
                                    </div>
                            </div>
                        </FormGroup>
                        <FormGroup className="mt-2">
                            <Label for="exampleEmail"
                                className={`${cx("loginContactUsFormWithLabel")}`}
                            >{t("your_email_address")}</Label>
                            <span className="text-danger ms-1">*</span>
                            <Input className="mt-1" type="email" name="email" id="exampleEmail" value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                    emailValidation(e)
                                }}                            />
                                {emailError?<div style={{fontWeight: 'bold',color: 'red',fontSize: '12px', marginTop:'5px'}}>Enter valid email Id</div>:""}
                        </FormGroup>
                        <FormGroup className="mt-2">
                            <Label for="verificationCode"
                                className={`${cx("loginContactUsFormWithLabel")}`}
                            >{t("verification_code")}</Label>
                            <span className="text-danger ms-1">*</span>
                            <div style={{ display: "inline-flex" }}>
                            <Input className="mt-1" type="text" name="text" id="verificationCode" autoComplete="off"
                                value={verficationCode}
                                onChange={(e) => {
                                    setVerificationCode(e.target.value)
                                    handleCaptcha1(e)
                                    }}  /> 
                                <span style={{marginLeft: '10px', marginTop: '10px'}}>
                                    <ClientCaptcha width = {64} height= {34} fontSize = {14} retryIcon = {captchaLoader} charsCount =  {4}
                                    retryIconSize={16} fontFamily = "HM Ampersand" captchaCode={(code:any) => { setCaptchaCode(code); setVerificationCode(''); setCaptchaError(false)}}/></span>
                                </div>
                                {captchaError?<div style={{fontWeight: 'bold',color: 'red',fontSize: '12px', marginTop:'5px'}}>Enter valid captcha</div>:""}    
                        </FormGroup>
                        {errorSupportRequest?<div style={{fontWeight: 'bold',color: 'red',fontSize: '12px', marginTop:'5px'}}>Error while creating support request</div>:""} 
                        <Button className="mt-3"
                            disabled={isSubmit ? false : true}
                            color={isSubmit ? "primary" : "secondary"}
                        >{t("submit")}</Button>

                        </Form>
                    </Col>
                </Row>
                </Container>}
    </div>);
};


function onCloseFAQ() {
    throw new Error("Function not implemented.");
}


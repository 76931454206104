import {
  SET_FORMS_STATE,
  CLEAR_FORMS_STATE,
  FormsActionTypes,
  UPDATE_FORM_STATE,
} from "../types/FormsTypes";

export const formState = (state = {}, action: FormsActionTypes) => {
  switch (action.type) {
    case SET_FORMS_STATE:
      return {
        ...state,
        [action.formName]: { validation: {}, ...action.payload },
      };
    case UPDATE_FORM_STATE:
      //@ts-ignore
      const updatedForm = { ...state[action.formName], ...action.payload };
      return { ...state, [action.formName]: updatedForm };
    case CLEAR_FORMS_STATE:
      return { ...state, [action.formName]: { validation: {} } };
    default:
      return state;
  }
};

import {
  CLEAR_RAW_STATE,
  RawActionTypes,
  SET_RAW_STATE,
} from "../types/rawTypes";

export const rawState = (state = {}, action: RawActionTypes) => {
  switch (action.type) {
    case SET_RAW_STATE:
      return { ...state, ...action.payload };
    case CLEAR_RAW_STATE:
      return {};
    default:
      return state;
  }
};

/// <reference path='../../../../dyo.d.ts' />
import React, { FC, useEffect, useState } from "react";
import classnames from "classnames/bind";
import { Row, Col } from "reactstrap";
import styles from "./styles.module.scss";
import axios from "axios";

const cx = classnames.bind(styles);

export const LoginLHS: FC = () => {
    const [bgImageResponse, setBgImageResponse] = useState("");
useEffect(() => {
    getBackgroundImage()
}, [])

const getBackgroundImage = async () => {
    try {
        const response: any = await axios.get(`${process.env.REACT_APP_API_URL_ENDPOINT}/Api/Login/BackGroundImage/`);
        setBgImageResponse(response.data);
    }
    catch {

    }
}
    return <div>
            <Row>
            <Col>
                <img id="loginLHSCss" className={`${cx("loginLHSCss")}`} src={`data:image/png;base64,${bgImageResponse}`}/>
            </Col>
                </Row>
    </div>
};
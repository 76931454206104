import { Suspense} from "react";
import React from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./redux/store";
import i18n from "./localization/i18n";
import { I18nextProvider } from "react-i18next";
import "./assets/global.scss";
import { Loading } from "./components/reusable/loading";
import ErrorBoundary from "./components/reusable/ErrorBoundary";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
   <Suspense fallback={<Loading occupyBrowserWindow={true} />}>
            <Provider store={store}>
                <I18nextProvider i18n={i18n}>
                    <ErrorBoundary>
                       <App/>
                    </ErrorBoundary>
                </I18nextProvider>
            </Provider>
        </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

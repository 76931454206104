export const SET_LIST_STATE = "SET_LIST_STATE";
export const CLEAR_LIST_STATE = "CLEAR_LIST_STATE";

interface SetListState {
  type: typeof SET_LIST_STATE | typeof CLEAR_LIST_STATE;
  payload: any;
  listName: string;
}

export type ListActionTypes = SetListState;

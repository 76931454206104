/// <reference path='../../../../dyo.d.ts' />
import React, { FC, useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import classnames from "classnames/bind"
import { NotificationModal } from "../../reusable/Notification/index";
import { FAQComponent } from "../containers/faqQuestions";
import { useTranslation } from "react-i18next";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import supImage from "../images/sup-image.png";
import hmBrandImage from "../images/hm_group_brands_logo.png";
import styles from "./styles.module.scss";
import App from '../../../appLogin';
import emailIcon from "../images/email_icon.png";

// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "../../../authConfig";
import axios from "axios";

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

type notificationDetail = {
    title: string;
    text: string;
    publishDate: Date;
    publishDateText: string;
  };
  

const cx = classnames.bind(styles);
export const LoginRHS: FC = () => {
    const { t } = useTranslation();
    const [notificationModal, setNotificationModal] = useState<boolean>(false);
    const [faqQuestions, setFaqQuestions] = useState<boolean>(false);
    const [notificationData,setNotificationData] = useState<notificationDetail[]>([]);
    
    
   
    // Handling input onFocus event
    const focusHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        setNotificationModal(true);
    };

    // Handling input onBlur event
    const blurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        setNotificationModal(false);
    };
    
    
    useEffect(() => {
        getNotificationData()
    }, [])

    const getNotificationData = async () => {
        try {
            const response: any = await axios.get(`${process.env.REACT_APP_API_URL_ENDPOINT}/Api/Login/Notification`);
            setNotificationData(response.data);
        }
        catch {

        }
    }
    
    return <div className={`${cx("margin-0")}`}>
        <div>
                {
                faqQuestions ?
                    <Container>
                        <Row className="d-flex flex-column justify-content-center align-items-start mt-4">
                            <Col className="d-flex flex-column justify-content-start align-items-start">
                                <FAQComponent
                                    onCloseFAQ={() => {
                                        setFaqQuestions(!faqQuestions)
                                    }} />
                            </Col>
                        </Row>
                    </Container>
                    :
                    <Container >
                        <Row className="d-flex flex-column justify-content-center align-items-start mt-5">
                            <Col className="d-flex flex-column justify-content-end align-items-end">
                                <p style={{ color: "#3E57B6", cursor: "pointer" }} id={"Popover1"} tabIndex={10}
                                onClick={() => {
                                    setNotificationModal(true)
                                    }}
                                    onBlur={blurHandler}>
                                    <NotificationImportantIcon />
                                    <span className={`ms-2 ${cx("notificationIcon")}`}>{t("notifications")}</span></p>
                    </Col>
                    {
                        notificationModal && <NotificationModal isOpen={notificationModal}
                            toggle={() => {
                                setNotificationModal(!notificationModal)
                            }}
                            idNotification="Popover1"
                            notificationData={notificationData}
                        />
                    }
            </Row>
                <Row className="d-flex flex-column justify-content-center align-items-start mt-5">
                    <Col md="12" className="d-flex flex-column justify-content-center align-items-center mt-4">
                        <img src={supImage} className={`${cx("supImageCss")}`}/>
                        </Col>
            </Row>
                <Row className="d-flex flex-column justify-content-center align-items-start mt-3">
                    <Col md="12" className="d-flex flex-column justify-content-center align-items-center">
                        <App pca={msalInstance}/>
                    </Col>
            </Row>
                        <Row className="d-flex flex-column justify-content-center align-items-start mt-2">
                            <Col md="12" className="d-flex flex-column justify-content-center align-items-center">

                                <span style={{ fontStyle:"HM Ampersand", fontSize:"12px", fontWeight:"400"}}>
                                    <span>{t("cant_login")}</span>
                                    <a className="d-inline-block ms-3 text-reset" style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setFaqQuestions(!faqQuestions)
                                        }}
                                    > {t("click_for_help")}
                                    </a>
                                </span>
                            </Col>
                        </Row>
                        <Row className="d-flex flex-column justify-content-center align-items-start mt-5" style={{ position: "relative" }}>
                            <Col md="12" className="d-flex flex-column justify-content-center align-items-center">
                                <img style={{ bottom: "20px", position: "absolute", top: "50px" }} src={hmBrandImage} className={`${cx("hmBrandImageCss")}`}/>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
    </div>;
};



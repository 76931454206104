import {
  SET_FORMS_STATE,
  CLEAR_FORMS_STATE,
  FormsActionTypes,
  UPDATE_FORM_STATE,
} from "../types/FormsTypes";
import { Dispatch } from "redux";

export const formsAction = (
  type: any,
  formName = "",
  data = {}
): FormsActionTypes => {
  return {
    type: type,
    payload: data,
    formName: formName,
  };
};

export const createFormState = (formName = "", data: {}) => {
  return (dispatch: Dispatch<FormsActionTypes>) => {
    dispatch(formsAction(SET_FORMS_STATE, formName, data));
  };
};

export const updateFormsState = (formName = "", data: {}) => {
  return (dispatch: Dispatch<FormsActionTypes>) => {
    dispatch(formsAction(UPDATE_FORM_STATE, formName, data));
  };
};

export const clearFormsState = (formName = "") => {
  return (dispatch: Dispatch<FormsActionTypes>) => {
    dispatch(formsAction(CLEAR_FORMS_STATE, formName));
  };
};

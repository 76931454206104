import i18next from "i18next";
import i18n from "./i18n";

export const fillAndUpdateLanguage = (callback: Function) => {
  if (i18n.isInitialized) {
    callback();
  }
  i18next.on("languageChanged", () => {
    callback();
  });
  i18next.on("loaded", () => {
    callback();
  });
};

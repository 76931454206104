import { combineReducers } from "redux";
import { alertState } from "./alertReducer";
import { formState } from "./formsReducer";
import { listState } from "./listReducer";
import { rawState } from "./rawReducer";

const rootReducer = combineReducers({
  alertState,
  rawState,
  formState,
  listState,
});

export default rootReducer;

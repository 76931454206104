import React, { FC } from "react";
import classnames from "classnames";
import { CustomSpinner } from "../Spinner";

interface Props {
  occupyBrowserWindow?: boolean;
  className?: string;
  loadingText?: string | null;
}
export const Loading: FC<Props> = React.memo(
  ({
    occupyBrowserWindow = false,
    loadingText = "",
    className = "",
  }: Props) => {
    return (
      <div
        className={classnames(
          "w-100 h-100 bg-transparent d-flex flex-column justify-content-center align-items-center",
          {
            "position-fixed": occupyBrowserWindow,
            "position-absolute": !occupyBrowserWindow,
          },
          className
        )}
        style={{ zIndex: occupyBrowserWindow ? 999 : 99, top: 0, left: 0 }}
      >
        <CustomSpinner
          id="loading-spinner"
          color="dark"
          otherProps={{
            children: "",
            style: { width: "50px", height: "50px" },
          }}
        />
        <span className="font-size-18 mt-3">{loadingText}</span>
      </div>
    );
  }
);

import { SetStateAction, useState } from "react";
import { useMsal } from "@azure/msal-react";
import {Button} from "reactstrap";
import { loginRequest } from "../../../authConfig";

export const SignInButton = () => {
    const { instance } = useMsal();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleLogin = (loginType: string) => {
        setAnchorEl(null);
        if (loginType === "popup") {
            instance.loginPopup(loginRequest);
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest);
        }
    }

    return (
        <div>
            <Button
                onClick={() => handleLogin("redirect")} key="redirect"
                color="primary" style={{ width: "316px", backgroundColor: "#5870D1" }}>
                Login
            </Button>
        </div>
    )
};